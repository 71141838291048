/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */

import "core-js/stable";
import "regenerator-runtime/runtime";
import "image-capture";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;
